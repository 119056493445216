// app global css in SCSS form
.rounded-borders {
  border-radius: 8px
}
.q-field--outlined .q-field__control {
  border-radius: 6px
}
.q-btn--rectangle {
  border-radius: 6px;
}
label {
  line-height: 1.8;
}
